<template>
  <div class="login-wrapper h-100">
    <div v-if="isLoading">
      <progress-indicator
        :loading-label="staticText.tryingToLogin"
      ></progress-indicator>
    </div>

    <div v-else>
      <b-container id="form-container-202012231345" class="mx-auto">
        <div v-if="showLogin && !isLoading" class="login-card">
          <div class="text-center mb-3">
            <a href="https://bao-solutions.com">
              <img
                src="../../../public/img/sidebar-logo.svg"
                alt="bao solutions"
                width="30%"
              />
            </a>
          </div>
          <div>
            <b-alert
              variant="info"
              :show="!!message"
              @dismissed="message = null"
              dismissible
            >
              {{ message }}
            </b-alert>
            <b-form>
              <div v-if="!showCodeInput" class="m-2">
                <b-form-group id="email-label-202012231345" class="input-wrapper">
                  <b-form-input
                    id="email-202012231345"
                    v-model.trim="authData.email"
                    type="email"
                    :placeholder="staticText.emailLabel"
                    :class="!!error ? 'password-error' : ''"
                    :disabled="basicLogin"
                  ></b-form-input>
                  <span class="input-label">Email</span>
                  <p
                    v-if="!!error && !basicLogin"
                    class="error-message mt-2 mb-0"
                  >
                    {{ error.detail ? error.detail : error }}
                  </p>
                </b-form-group>
                <div v-if="showTeamChoices"
                     class="mb-3"
                >
                  <vue-multiselect :options="availableTeams"
                                   v-model="authData.selectedTeam"
                                   label="name"
                                   track-by="schema_name"
                                   :show-labels="false"
                                   :placeholder="staticText.selectTeamLabel"
                                   @input="changeSchemaOnTeamChange"
                  ></vue-multiselect>
                </div>

                <b-form-group
                  id="password-label-202012231345"
                  v-if="basicLogin"
                >
                  <b-form-input
                    id="password-102012030654"
                    v-model.trim="authData.password"
                    type="password"
                    autofocus="autofocus"
                    :placeholder="staticText.passwordLabel"
                    aria-describedby="inputLiveHelp inputLiveFeedback"
                    :class="!!error ? 'password-error' : ''"
                  >
                  </b-form-input>
                  <p v-if="!!error" class="error-message mt-2 mb-0">
                    {{ error.detail ? error.detail : error }}
                  </p>
                  <img
                    src="../../../public/img/icons/orange-close-icon.svg"
                    v-if="!!error && authData.password.length"
                    class="reset-password-icon"
                    @click="resetError"
                  />
                </b-form-group>
                <b-button
                  v-if="basicLogin"
                  class="mb-2 px-4 font-size-14"
                  @click="basicLogin = false"
                >
                  <i class="fas fa-chevron-left"></i>
                  {{ staticText.backToLoginBtn }}
                </b-button>
                <b-form-group>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="col-md-12"
                    @click.prevent="handleLogin"
                  >
                    {{ loginBtnLabel }}
                  </b-button>
                </b-form-group>
                <a
                  v-if="basicLogin"
                  class="m-2 mt-0 link"
                  href=""
                  @click.prevent="forgotPasswordLinkClicked()"
                >
                  <img
                    src="../../../public/img/icons/key-icon.svg"
                    class="mr-2"
                  />
                  {{ staticText.forgotPasswordLink }}
                </a>
              </div>

              <div v-if="showCodeInput" class="mb-4 mx-2 text-center">
                <p id="new-code-created-msg-213191032" class="mb-2">
                  {{ staticText.newCodeCreatedMessage }}
                </p>
                <b-form-group id="code-label-20221625">
                  <b-form-input
                    id="code-20221625"
                    v-model.trim="otp"
                    :placeholder="staticText.codeLabel"
                    :class="!!error ? 'password-error' : ''"
                    type="text"
                  ></b-form-input>
                </b-form-group>
                <p v-if="!!error" class="error-message my-2">
                  {{ error }}
                </p>
                <a
                  id="send-new-code-link-213191043"
                  class="my-2 link"
                  href=""
                  @click.prevent="createNewCode"
                >
                  {{ staticText.createCodeBtnLabel }}
                </a>
                <b-form-group id="code-submit-btn-213191037">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="col-md-12"
                    @click.prevent="verifyCode"
                  >
                    {{ staticText.codeVerifyBtn }}
                  </b-button>
                </b-form-group>
              </div>
            </b-form>
          </div>
        </div>

        <div v-else class="recovery-card">
          <div class="recovery-card-header p-2">
            <div class="ml-3">
              <img src="../../../public/img/icons/key-icon.svg" class="mr-2"/>
              <span>{{ staticText.accountRecoveryLabel }}</span>
            </div>
          </div>
          <b-form v-if="!showEmailSent" class="recovery-card-body">
            <div class="mx-auto mb-4">
              <h4 class="mx-auto mb-3 h5 text-center font-weight-bold">
                {{ staticText.accountRecoveryLabel }}
              </h4>
              <p class="text-center">{{ staticText.accountRecoveryText }}</p>
            </div>

            <div class="mb-3">
              <b-form-group
                id="reset-password-email-label-202012231345"
                label-align="left"
                label-for="reset-password-email-202012231345"
                :invalid-feedback="staticText.emailInvalidFeedback"
              >
                <b-form-input
                  id="reset-password-email-202012231345"
                  v-model="emailForReset"
                  type="email"
                  aria-describedby="inputLiveHelp inputLiveFeedback"
                  :state="emailForResetState"
                  :placeholder="staticText.emailLabel"
                ></b-form-input>
              </b-form-group>
            </div>

            <div>
              <div v-if="sendingEmail">
                <div class="my-2 text-primary">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> {{ staticText.sendingEmailLabel }}</strong>
                </div>
              </div>

              <div v-else class="d-flex justify-content-between">
                <b-btn
                  variant="secondary"
                  class="px-4 font-size-14"
                  @click="backToLogin"
                >
                  <i class="fas fa-chevron-left"></i>
                  {{ staticText.backToLoginBtn }}
                </b-btn>

                <b-button
                  type="submit"
                  class="px-5 font-size-14"
                  variant="primary"
                  @click.prevent="submittedPasswordResetEmail"
                >
                  {{ staticText.recoveryMailBtn }}
                </b-button>
              </div>
            </div>
          </b-form>

          <div v-else class="p-3">
            <b-alert variant="info" :show="true">
              {{ staticText.passwordRecoveryAlert }}
            </b-alert>
            <b-btn
              variant="secondary"
              class="px-4 font-size-14"
              @click="backToLogin"
            >
              <i class="fas fa-chevron-left"></i>
              {{ staticText.backToLoginBtn }}
            </b-btn>
          </div>
        </div>
        <div class="privacy-policy">
          <a href="https://www.bao.ai/datenschutzerklaerung/" target="__blank">Datenschutzerklärung</a>
          <span class="mx-1">/</span>
          <a href="https://www.bao.ai/privacy-policy/?lang=en" target="__blank">Privacy Policy</a>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "./ProgressIndicator"
import VueMultiselect from "vue-multiselect"

import {
  clearStorage,
  getEmail,
  getSchemaName,
  getSocialOTT,
  getBaoUserFromStorage,
  isRefreshTokenExpired,
  isSocialAuthInProgress,
  isSocialAuthProgressOldThenClear,
  lookupEmail,
  removeSocialAuthInProgress,
  setEmail,
  setSchemaName,
  clearSchemaInfo,
  setSocialAuthInProgress,
  setSocialOTT,
  setTokenPair,
  areFreshAuthTokens,
  popupLoginForSwift,
  getLocalStorage
} from "@/utils/authenticationManager"

import { setCookie } from "@/utils/utils"

export default {
  name: "LoginPage",
  components: {
    ProgressIndicator,
    VueMultiselect
  },
  data () {
    return {
      authData: {
        email: "",
        password: "",
        selectedTeam: null
      },
      showTeamChoices: false,
      availableTeams: [],
      axios,
      showCodeInput: false,
      otp: "",
      error: null,
      shouldAttemptTokenNP: !!this.getSocialOTT(),
      popupWindow: null,
      passwordState: null,
      isLoading: false,
      message: null,
      emailRegexTest: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showLogin: true,
      intervalID: null,
      emailForReset: "",
      showEmailSent: false,
      sendingEmail: false,
      recoverySubmitted: false,
      basicLogin: false,
      loginPath: null,
      loginValidator: null,
      openedWindow: null,
      swiftLoginButtonStatus: false,
      staticText: {
        selectTeamLabel: "Select Team",
        incorrectCredentialsAlert: "Username or password incorrect!",
        invalidEmailText: "Email address is not valid",
        emailLabel: "Email",
        passwordLabel: "Password",
        codeLabel: "Security Code",
        codeVerifyBtn: "Verify Code",
        createCodeBtnLabel: "Send New Code",
        forgotPasswordLink: "Forgot your password?",
        accountRecoveryLabel: "Account Recovery",
        accountRecoveryText:
          "You can recover your forgotten credentials by providing your account email address and submitting the form below.",
        emailInvalidFeedback: "Please enter a valid email address.",
        sendingEmailLabel: "Sending Email ...",
        recoveryMailBtn: "Send Recovery Mail",
        iFrameLoginBtnLabel: "Login",
        backToLoginBtn: "Back",
        passwordRecoveryAlert:
          "A password recovery email has been sent to your mail address.\n" +
          "            Please follow the provided instructions in the email.",
        tryingToLogin: "Loading ...",
        newCodeCreatedMessage:
          "A security code was sent to your email. Please type it in the field below and click Verify Code.",
        emptyCodeErrorMsg: "Enter Security Code",
        wrongAccountSelected: "Make sure you are logged in with your social account and complete login process with correct account",
        swiftRefreshHint: "Refresh this page after successful login, if it does not refresh automatically."
      }
    }
  },
  computed: {
    ...mapGetters({ authenticatedUser: "auth/user" }),
    emailState () {
      if (this.authData.email === "") return null
      return this.emailRegexTest.test(this.authData.email)
    },
    emailForResetState () {
      return (
        !!this.emailForReset &&
        this.emailForReset !== "" &&
        this.emailRegexTest.test(this.emailForReset)
      )
    },
    loginBtnLabel () {
      return this.basicLogin ? "Login" : "Submit"
    },
    changeSchemaOnTeamChange () {
      return (selectedTeam) => {
        this.setSchemaName(selectedTeam.schema_name)
      }
    }
  },

  async mounted () {
    // set active speech recognition to False when user lands on login page
    setCookie("activeSpeechRecognition", "false")

    const query = this.$route.query
    this.isLoading = this.isSocialAuthInProgress()
    // if token is available but the bao-user not available then call the api to get it
    // this method (this.getUser()) calls two api: api/users/get_integrated_crms, api/users/profile/
    if (!this.getBaoUserFromStorage() && this.isTokenAvailable()) {
      await this.getUser()
    }
    this.authData.email = this.lookupEmail()
    const user = this.getBaoUserFromStorage()
    this.message = query && query.message ? query.message : null
    if (query && user) {
      this.isLoading = true
      this.getUser()
        .then(() => {
          if (query.redirect && query.redirect.length > 0) {
            this.pushOrReplaceRoute(query.redirect)
            return
          }
          this.pushOrReplaceRoute("/")
        })
        .catch(() => {
          this.isLoading = false
        })
    }

    // no-password token login
    if (this.shouldAttemptTokenNP && this.isSocialAuthInProgress()) {
      this.performLogin(
        "/api/token-np/",
        {
          email: this.getEmail(),
          socialOTT: this.getSocialOTT()
        },
        false
      )
    }

    // clear old SSO data
    if (this.isSocialAuthProgressOldThenClear()) {
      this.isLoading = false
      this.error = null
    }

    if (!this.isSocialAuthInProgress() && localStorage.getItem("custom_err")) {
      // set by backend template
      this.error = localStorage.getItem("custom_err")
      localStorage.removeItem("custom_err")
    }

    // If refresh token is present and has expired,
    // then don't show loading
    if (this.isRefreshTokenExpired()) {
      this.isLoading = false
      this.clearStorage()
    }

    // Remove the popup window if it is still open after social login
    // and login screen opens up in the popup window
    if (
      window.opener &&
      window.opener !== window &&
      window.location.pathname === "/login"
    ) {
      // make the opener window reload
      window.opener.location.reload()
      window.close()
    }
  },
  methods: {
    ...mapActions({
      getUser: "auth/getUser",
      updateLanguage: "I18nStore/getI18nData"
    }),
    setTokenPair,
    setSchemaName,
    getSchemaName,
    clearSchemaInfo,
    getBaoUserFromStorage,
    clearStorage,
    getSocialOTT,
    setSocialOTT,
    getEmail,
    setEmail,
    lookupEmail,
    getLocalStorage,
    isRefreshTokenExpired,
    isSocialAuthInProgress,
    setSocialAuthInProgress,
    removeSocialAuthInProgress,
    isSocialAuthProgressOldThenClear,
    areFreshAuthTokens,
    popupLoginForSwift,
    backToLogin () {
      this.showEmailSent = false
      this.showLogin = true
    },
    resetError () {
      this.error = null
      this.authData.password = ""
    },
    createNewCode () {
      this.authData.createNewCode = true
      return this.axios
        .post("/api/token/", this.authData)
        .then(response => {
          if (response.status === 201) {
            this.authData.createNewCode = false
            this.error = null
            this.message = response.data.message
          }
        })
        .catch(err => {
          this.authData.createNewCode = false
          this.error = err.response.data.message
        })
    },
    isTokenAvailable () {
      const accessToken = this.getLocalStorage("access_token")
      const refreshToken = this.getLocalStorage("refresh_token")
      const refreshTokenCreatedAt = this.getLocalStorage("refresh_token_created_at")
      if (accessToken && refreshToken && refreshTokenCreatedAt) {
        return true
      }
      return false
    },
    async verifyCode () {
      if (this.otp.length === 0) {
        this.error = this.staticText.emptyCodeErrorMsg
        return
      }
      this.authData = {
        ...this.authData,
        code: this.otp
      }
      await this.performLogin("/api/token/", this.authData, true)
    },
    // create window popup for social authentication
    createTabOrPopup (popupLogin = false) {
      const SOCIAL_LOGIN_URL = process.env.VUE_APP_BASEURL + this.loginPath
      const windowName =
        "bao_social_oauth_login_" + Math.floor(Math.random() * 100)

      // TODO: We should look into shape and positioning of the popup
      const popupHeight = 800
      const popupWidth = 800
      const popupTop = (window.innerHeight - popupHeight) / 2
      const popupLeft = (window.innerWidth - popupWidth) / 2

      let newWindowAttr = [SOCIAL_LOGIN_URL, windowName]
      // Passing the following window attributes (dimensions) as a string creates a new window.
      // Otherwise its a new tab which auto closes.
      const newWindowAttrString = `width=${popupHeight}, height=${popupWidth},
                                  top=${popupTop}, left=${popupLeft}`
      if (popupLogin) {
        newWindowAttr = newWindowAttr.concat(newWindowAttrString)
      }
      this.popupWindow = window.open(...newWindowAttr)
      localStorage.setItem("tab_or_popup_window", true)
    },
    async handleLogin () {
      if (!this.basicLogin) {
        const email = this.authData.email
        const tenant = this.authData.selectedTeam
        this.setEmail(email) // email saved in cookie
        await this.axios
          .post("/api/login/", { email, tenant }, {
            validateStatus: function (status) {
              return status >= 200 && status <= 300
            }
          })
          .then(response => {
            if (response.status === 300) {
              this.showTeamChoices = true
              this.availableTeams = response.data
              return
            }
            if (response.status === 200) {
              this.showTeamChoices = false
              const { loginPath, socialOTT, schemaName } = response.data
              this.setSchemaName(schemaName)
              if (!loginPath) {
                this.basicLogin = true
              } else {
                // trigger social login
                this.setSocialAuthInProgress()
                this.setSocialOTT(socialOTT)
                this.shouldAttemptTokenNP = true
                this.isLoading = true
                this.loginPath = loginPath

                // if window is in a iframe, then open a popup or tab
                if (window.self !== window.top) {
                  this.createTabOrPopup(this.popupLoginForSwift())
                  // keep checking if the user credentials are stored. If so,
                  // reload the window
                  const loggedInIntervalCheck = setInterval(() => {
                    const isUserLoggedIn = this.areFreshAuthTokens()
                    const currentPath = window.location.pathname
                    if (currentPath !== "/call") {
                      if (isUserLoggedIn) {
                        this.$router.push("/call")
                      }
                    }
                  }, 1000)
                  // clear interval after 30 seconds
                  setTimeout(() => {
                    clearInterval(loggedInIntervalCheck)
                  }, 30000)
                } else {
                  // If not in iframe, then redirect to social login page
                  // redirect to social login page
                  const SOCIAL_LOGIN_URL =
                    process.env.VUE_APP_BASEURL + this.loginPath
                  window.location.replace(SOCIAL_LOGIN_URL)
                }
              }
            }
          })
          .catch(err => {
            console.log(err.response)
            this.error = this.staticText.invalidEmailText
          })
        return
      }
      // default email/password login
      if (this.basicLogin) {
        this.authData.schema_name = this.getSchemaName()
        this.performLogin("/api/token/", this.authData, true)
      }
    },
    async performLogin (path, authData, allow2FA = false) {
      this.isLoading = true
      await this.axios
        .post(path, authData)
        .then(response => {
          if (allow2FA && response.status === 201) {
            // If 2FA flow
            this.isLoading = false
            this.showCodeInput = true
            return
          }
          // Login was successful
          // save new tokens
          // clear localStorage of socialOTT and email
          const { access, refresh } = response.data
          const schemaName = response.data.schema_name
          this.setTokenPair(access, refresh)
          this.setSchemaName(schemaName)
          this.shouldAttemptTokenNP = false

          const isTabOrPopup = localStorage.getItem("tab_or_popup_window")
          if (isTabOrPopup === "true") {
            localStorage.removeItem("tab_or_popup_window")
            window.close()
          }
          // Close the popup if the app is running in the popup
          if (window.opener && window.opener !== window) {
            window.close()
          }

          this.showCodeInput = false
        })
        .then(async () => {
          return this.getUser()
            .then(() => {
              this.isLoading = this.basicLogin || this.isSocialAuthInProgress()
              this.removeSocialAuthInProgress()
            })
            .then(() => {
              this.updateLanguage()
              const query = this.$route.query
              if (query.redirect && query.redirect.length > 0) {
                this.pushOrReplaceRoute(query.redirect)
              } else {
                this.pushOrReplaceRoute({ path: "/" })
              }
            })
        })
        .catch(err => {
          if (err.response) {
            this.isLoading = false
            if (this.basicLogin) {
              if (err.response.status === 401) {
                this.error = this.staticText.incorrectCredentialsAlert
              } else if (err.response.data) {
                let errorData = err.response.data
                errorData = errorData.message
                  ? errorData.message
                  : errorData.detail
                this.error =
                  errorData || this.staticText.incorrectCredentialsAlert
              }
            } else {
              this.error = this.staticText.wrongAccountSelected
            }
          }
          this.clearStorage()
          this.clearSchemaInfo()
        })
    },
    submittedPasswordResetEmail () {
      if (
        !this.emailForReset ||
        this.emailForReset === "" ||
        !this.emailRegexTest.test(this.emailForReset)
      ) {
        return
      }
      this.sendingEmail = true
      this.axios
        .post("/api/users/password_reset", { email: this.emailForReset })
        .then(
          () => {
            this.showEmailSent = true
            this.sendingEmail = false
          },
          error => {
            this.error = error.response
          }
        )
        .catch(error => {
          console.log(error)
        })
    },
    checkIfUserIsLoggedIn () {
      this.loginValidator = setInterval(() => {
        const accessToken = this.getLocalStorage("access_token")
        const refreshToken = this.getLocalStorage("refresh_token")
        const refreshTokenCreatedAt = this.getLocalStorage("refresh_token_created_at")
        if (accessToken && refreshToken && refreshTokenCreatedAt) {
          this.getUser()
            .then(() => {
              this.stopLoginCheck()
              return true
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }, 500)
    },
    stopLoginCheck () {
      clearTimeout(this.loginValidator)
      this.closeOpenedAndRefreshCurrentTab()
    },
    closeOpenedAndRefreshCurrentTab () {
      this.openedWindow.close()
      this.$router.go()
    },
    openLoginPageInNewTab () {
      this.swiftLoginButtonStatus = true
      this.checkIfUserIsLoggedIn()
      const route = this.$router.resolve({ path: "/login" })
      this.openedWindow = window.open(route.href)
    },
    forgotPasswordLinkClicked () {
      this.showLogin = false
      this.emailForReset = this.authData.email
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.form-input input:not(:placeholder-shown):valid {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $background;
  backdrop-filter: blur(20px);
}

.login-card {
  width: 410px;
  padding: 60px 80px;
  background-color: $white40;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
}

.recovery-card {
  width: 410px;
  background-color: $white;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
}

@media (max-width: 440px) {
  .login-card {
    padding: 60px 40px;
  }
  .login-card,
  .recovery-card {
    width: 100%;
  }
}

.recovery-card-header {
  color: $slate80;
  background-color: $grey;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.recovery-card-body {
  padding: 40px;

  h4 {
    color: $black;
  }
}

.link {
  color: $slate80;
}

.password-error {
  border: 1px solid $orange80;
  border-radius: 12px;
}

.error-message {
  font-size: 12px;
  line-height: 14px;
  color: $orange;
}

.reset-password-icon {
  cursor: pointer;
  position: relative;
  bottom: 65px;
  left: 200px;
}

.privacy-policy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
  a {
    text-decoration: underline;
  }
}

.refresh-hint{
  text-align: center;
  font-size: 12px;
  padding-top: 8px;
  color: #7F8197;
}
</style>
